/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { HomePage } from "./home";
import PropTypes from "prop-types";
import { defaultTheme, defaultDarkTheme } from "../../constants";

class PbPreviewBase extends React.Component {
  render() {
    let theme = get(this.props, ["config", "pagebuilder-config", "home", "pageSetting", "bgColor"], defaultTheme);
    if (this.props.enableDarkMode) {
      theme = get(this.props, ["config", "pagebuilder-config", "home", "pageSetting", "darkBgColor"], defaultDarkTheme);
    }

    return (
      <div style={{ backgroundColor: theme }}>
        {/* HomePage will be replaced by proper loader when section preview is implemented. */}
        <HomePage {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.qt.config,
    enableDarkMode: get(state, ["header", "isDarkModeEnabled"], false),
  };
}
PbPreviewBase.propTypes = {
  config: PropTypes.object,
  enableDarkMode: PropTypes.bool,
};

export const PbPreview = connect(mapStateToProps)(PbPreviewBase);
